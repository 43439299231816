import React, {useState, useEffect} from "react";
import "./Account.css";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import AccountsService from "../../services/AccountsService";
import Loader from "../Loader/Loader";
import ProductsService from "../../services/ProductsService";
import GenericError from "../Utilities/GenericError";
import PopUp from "../Utilities/PopUp";
import TextBox from "./components/TextBox/TextBox";
import CheckBox from "./components/CheckBox/CheckBox";
import CouponsService from "../../services/CouponsService";
import CustomDatePicker from "./components/DatePicker/DatePicker";
import CustomSelectBox from "./components/CustomSelectBox/CustomSelectBox";
import { isValidPhoneNumber } from "react-phone-number-input/mobile";
import CardValidationService from "../../services/CardValidationService";
import PinVerification from "../PinVerification/PinVerification";
import {formatDate, gradientGetter} from "./utils";
import {useLoading} from "../../context/LoadingContext";
import {useAuth} from "../../context/AuthContext";
import CardCarousel from "./components/CardCarousel/CardCarousel";
import {useAccount} from "../../context/AccountContext";

// const useStyles = makeStyles({
//   accordion: {
//     backgroundColor: "#000000",
//     color: "#FFFFFF",
//     border: "2px solid #7F7F7F",
//     textAlign: "left",
//     borderRadius: "8px",
//     "&:first-child": {
//       borderRadius: "8px",
//     },
//     "&:last-child": {
//       borderRadius: "8px",
//     },
//     "&:hover, &:focus": {
//       borderColor: "#FF0078",
//     },
//   },
//   icon: {
//     color: "#FF0078",
//   },
//   buttonLabel: {
//     fontSize: "14px",
//     fontWeight: "300",
//     color: "#3A86FF",
//   },
//   grid: {
//       maxWidth: "min-content"
//   }
// });

function Account() {
    let navigate = useNavigate();

    //context
    let account = useAccount();
    const {isLoading, removeLoading, addLoading} = useLoading();
    const {loggedIn, setCheckLoggedIn} = useAuth();

    // const [expanded, setExpanded] = useState(false);
    // const [paymentProfile, setPaymentProfile] = useState(false);
    // const [maskedCardNumber, setMaskedCardNumber] = useState("");
    // const mediaMatch = window.matchMedia("(min-width: 600px)");
    // const [matches, setMatches] = useState(mediaMatch.matches);
    // const [deleteCardPopUp, setDeleteCardPopUp] = useState(false);
    // const [customCouponMessage, setCustomCouponMessage] = useState("");

    const [canUpgrade, setCanUpgrade] = useState(false);
    const [periodEndDate, setPeriodEndDate] = useState("");
    const [hasUnsubscribed, setHasUnsubscribed] = useState(false);
    const [hasUsedCoupon, setHasUsedCoupon] = useState(false);
    const [hasFreeProduct, setHasFreeProduct] = useState(false);
    // const [voucher, setVoucher] = useState(null);
    // const [showVoucher, setShowVoucher] = useState(false);

    //Phone number verification
    const [verificationPopup, setVerificationPopup] = useState(false);
    const [verificationErrorPopup, setVerificationErrorPopup] = useState(false);
    const [verificationSuccessPopup, setVerificationSuccessPopup] = useState(false);

    const [paymentProfileList, setPaymentProfileList] = useState([]);


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        gender: ''
    });
    const [phoneNum, setPhoneNum] = useState('')

  // const handleAccordionChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

    const onUnsubscribeHandle = () => {
        // setLoading(true);
        account.clearSessionCoupon();
    // const temp = ()=>{
    //     console.log(account.userData.subscription.product.id)
    // }
        navigate("/questionnaire",  {
        state: {
            q: 'unsubscribe',
            productId: account.userData.subscription.product.id,
            pricePoint: account.userData.subscription.product.product_price_point_handle,
            subscriptionState: account.userData.subscription.state,
            next_assessment_at: account.userData?.subscription?.next_assessment_at
        }});
    };

    //Marketing field handling
    const [marketingDisabled, setMarketingDisabled] = useState(false)
    const [acceptMarketingCampaigns, setAcceptMarketingCampaigns] = useState(false);
    const onAcceptMarketingChange = () => {
        setMarketingDisabled(true);
    let value = acceptMarketingCampaigns;
    setAcceptMarketingCampaigns(!value);
    AccountsService.patchUser({ acceptedMarketing: !value })
      .then((response) => {
          setMarketingDisabled(false);
      })
      .catch((error) => {
          setMarketingDisabled(false);
      });
    };



    useEffect(() => {
        if(!(isLoading("INIT"))){
            addLoading("INIT")
        }
        if(loggedIn){
            AccountsService.getProfile()
                .then((response) => {
                    account.setUserData(response.data);
                    setAcceptMarketingCampaigns(response.data.acceptedMarketing);
                    setFormData({
                        firstName: response.data.firstName && response.data.firstName !== "Anonymous" ? response.data.firstName : "",
                        lastName: response.data.lastName && response.data.lastName !== "User" ? response.data.lastName : "",
                        dateOfBirth: response.data.dateOfBirth,
                        gender: response.data.gender
                    })
                    setPhoneNum(response.data.phoneNumber ? response.data.phoneNumber : "")

                    setPeriodEndDate(formatDate(response.data.subscription?.current_period_ends_at));

                    if(response.data.paymentProfileList?.length > 1){
                        const temp = response.data.paymentProfileList?.findIndex(item => item.id === response.data.subscription?.credit_card.id)
                        setPaymentProfileList([response.data.paymentProfileList[temp], ...response.data.paymentProfileList?.slice(0, temp), ...response.data.paymentProfileList?.slice(temp + 1)])
                    }else{
                        setPaymentProfileList(response.data.paymentProfileList)
                    }

                // setMaskedCardNumber(
                //     response.data.paymentProfile
                //         ? response.data.paymentProfile.masked_card_number
                //         : ""
                // );
                // if (response.data.vouchers) {
                //   let voucher = response.data.vouchers.find(
                //       (element) => element.provider === "VILLAGE"
                //   );
                //   if (voucher) {
                //     setVoucher(voucher);
                //   }
                // }

                    if (response.data.subscription) {
                  // if (response.data.subscription.coupon_code) {
                  //   let tempObj = couponsData.find(
                  //       (element) =>
                  //           element.name === response.data.subscription.coupon_code
                  //   );
                  //   if (tempObj) {
                  //     setCustomCouponMessage(tempObj.text);
                  //   } else {
                  //     setCustomCouponMessage("Έχεις εξαργυρώσει κωδικό προσφοράς");
                  //   }
                  // } else {
                  //   setCustomCouponMessage("");
                  // }
                        setHasUsedCoupon(response.data.subscription.coupon_code ? true : false);
                  // setPaymentProfile(!!response.data.paymentProfile);
                        ProductsService.getProductReferenceStandalone({
                            productId: response.data.subscription.product.id,
                            pricePoint: response.data.subscription.product.product_price_point_handle,
                        })
                            .then((res) => {
                                // setShowVoucher(res.data.displayName === "Village");
                                setHasFreeProduct(!!res.data.downgrades && res.data.downgrades.length === 0)
                                setCanUpgrade((!!res.data.upgrades && res.data.upgrades.length > 0) || (!!res.data.downgrades && res.data.downgrades.length > 0));
                                const nextProductIdCheck =
                                    response.data.subscription.next_product_id !== undefined
                                        ? response.data.subscription.next_product_id.toString() ===
                                        process.env.REACT_APP_FREE_PRODUCT_ID.toString()
                                            ? true
                                            : false
                                        : false;
                                setHasUnsubscribed(nextProductIdCheck);
                            })
                            .catch((e)=>{

                            })
                    }
                })
                .catch((e)=>{

                })
                .finally(()=>{
                    removeLoading("INIT")
                })
        }
    }, [loggedIn]); //eslint-disable-line react-hooks/exhaustive-deps

  // const questions = [
  //   {
  //     title: "Ιστορικό χρεώσεων",
  //     description: <PaymentHistory userData={account.userData} />,
  //   },
  //   // {
  //   //   title: t("accountAccordeon1"),
  //   //   description: t("accountAccordeon1Content"),
  //   // },
  //   // {
  //   //   title: t("accountAccordeon2"),
  //   //   description: t("accountAccordeon2Content"),
  //   // },
  //   {
  //     title: t("accountAccordeonMarketingSettings"),
  //     description: (
  //       <label className="checkbox-container">
  //         <input
  //           type="checkbox"
  //           checked={acceptMarketingCampaigns}
  //           onChange={onAcceptMarketingChange}
  //         />
  //         <span className="checkmark"></span>
  //         <span className="text">
  //           {t("accountAccordeonMarketingSettingsContent")}
  //         </span>
  //       </label>
  //     ),
  //   },
  //   {
  //     title: t("accountAccordeonSubscriptionManagement"),
  //     description: (
  //       <Button variant="text" className="blue" onClick={onUnsubscribeHandle}>
  //         {t("accountAccordeonSubscriptionManagementContent")}
  //       </Button>
  //     ),
  //     checkHidden: true,
  //   },
  //   // {
  //   //   title: t("accountAccordeon5"),
  //   //   description: t("accountAccordeon5Content"),
  //   // },
  //   //{
  //   //  title: t("accountAccordeonAppInfo"),
  //   //  title: t("accountAccordeonAppInfo"),
  //   //  description: t("accountAccordeonAppInfoContent"),
  //   //},
  // ];

  const onChangePaymentInfo = (e) => {
    e.preventDefault();
    account.setChargifyAccess(true);
    account.setChangePayment(true);
    navigate("/ChargifyForm");
  };

  // const onDeleteCardDetailsRequest = () => {
  //   setDeleteCardPopUp(true);
  // };

  // const onDeleteCardDetails = () => {
  //   let subscriptionId = account.userData.subscription
  //     ? account.userData.subscription.id
  //     : -1;
  //   AccountsService.unsubscribeSubscription({ subscriptionId: subscriptionId })
  //     .then((response) => {
  //       window.location.reload(false);
  //       setDeleteCardPopUp(false);
  //     })
  //     .catch((error) => {
  //       // console.log("error!");
  //       // console.log(error);
  //     });
  // };

  const onUpgrade = () => {
    setCheckLoggedIn(false);
    account.clearSessionCoupon();
    navigate("/PickProgram");
  };

  const onCashCoupon = () => {
    setCheckLoggedIn(false);
    navigate("/CashCoupon");
  };

  // const onKeepWatching = () => {
  //   window.open(`${process.env.REACT_APP_ACCOUNT_CTA_LINK}`, "_self");
  // };

  const sendVerificationEmail = () =>{
    addLoading("SENTEMAIlVER")
    AccountsService.actions({redirectUri: process.env.REACT_APP_VERIFY_EMAIL_REDIRECT_URI, action: "VERIFY_EMAIL"})
        .then(()=>{
          setVerificationErrorPopup(false);
          setVerificationSuccessPopup(true);
        })
        .catch((err)=>{
          setVerificationErrorPopup(true);
        })
        .finally(()=>{
          setVerificationPopup(false);
          removeLoading("SENTEMAIlVER")
        })
  }

  const logoutFromAllDevices = (e)=>{
      e.preventDefault();
      addLoading("LOGOUTALLDEV")
      AccountsService.logoutAll()
          .then(()=>{

          })
          .catch((err)=>{
              console.error("Error logout from all devices", err);
          })
          .finally(()=>{
              removeLoading("LOGOUTALLDEV")
          })
  }

    const [isHistoryExpanded, setIsHistoryExpanded] = useState(false)
    const [isEditing, setIsEditing] = useState(false)


    //progress bar
    const [progressBarCompletion, setProgressBarCompletion] = useState("")
    const [persentCompletion, setPersentCompletion] = useState(13)

    useEffect(()=>{
        let progress =2;
        if(account.userData?.emailVerified){
            progress++;
        }
        if(account.userData?.firstName && account.userData?.firstName !== "Anonymous"){
            progress++;
        }
        if(account.userData?.lastName && account.userData?.lastName !== "User"){
            progress++;
        }
        if(account.userData?.dateOfBirth){
            progress++;
        }
        if(account.userData?.gender){
            progress++;
        }
        if(account.userData?.phoneNumber){
            progress++;
        }
        // if(acceptMarketingCampaigns){
        //     progress++;
        // }

        setProgressBarCompletion("complete"+progress)
        setPersentCompletion(Math.ceil(12.5*progress))
    }, [account.userData])

    const handleInputChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const handlePersonalSubmit = () =>{
        addLoading("ACCOUNTPERSONALSUBMIT");
        setIsEditing(false);
        AccountsService.patchUser(Object.entries({...formData, phoneNumber: !!phoneNum && phoneNum !== account.userData?.phoneNumber && isValidPhoneNumber(phoneNum) ? phoneNum :''})
            .filter(([key, value]) => value  && value.trim() !== '')
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {}))
            .then((response) => {
                navigate(0);
            })
            .catch((error) => {
                if (error.response.data.code === 403) {
                    if(error.response.data.token){
                        if(!!phoneNum && phoneNum !== account.userData?.phoneNumber){
                            if(isValidPhoneNumber(phoneNum)){
                                sessionStorage.setItem("cardValidationToken", error.response.data.token)
                                CardValidationService.generateOTP(
                                    {
                                        phoneNumber: phoneNum,
                                    },
                                    error.response.data.token
                                )
                                    .then((response) => {
                                        setPhoneNumberVerify(true);
                                        sessionStorage.setItem("phoneVerificationToken", response.data.token);
                                    })
                                    .catch((error) => {
                                        //todo error return from back
                                        console.log("Failure: generateOTP")
                                    })
                                    .finally(()=>{
                                        removeLoading("ACCOUNTPERSONALSUBMIT")
                                    })
                            }else{
                                //should never go here
                                setIsPhoneNumberError(true);
                                setIsEditing(true);
                                removeLoading("ACCOUNTPERSONALSUBMIT")
                            }
                        }else {
                            //should never go here
                            removeLoading("ACCOUNTPERSONALSUBMIT")
                        }
                    }else{
                        removeLoading("ACCOUNTPERSONALSUBMIT")
                        //todo error return from back
                    }
                }else{
                    removeLoading("ACCOUNTPERSONALSUBMIT")
                    console.log("Failure: patchUser")
                    navigate(0);
                    //todo error return from back
                }
            });
    }

    const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
    const phoneNumberCodes = [{text: "GR", code: "+30"}, {text: "CY", code: "+357"}]
    const [phoneNumberVerify, setPhoneNumberVerify] = useState(false);
    const closeEdit = ()=>{
        setIsPhoneNumberError(false);
        setFormData({
            firstName: account.userData?.firstName && account.userData?.firstName !== "Anonymous" ? account.userData?.firstName : "",
            lastName: account.userData?.lastName && account.userData?.lastName !== "User" ? account.userData?.lastName : "",
            dateOfBirth: account.userData?.dateOfBirth,
            gender: account.userData?.gender
        })
        setPhoneNum(account.userData?.phoneNumber ? account.userData?.phoneNumber : "");
        setIsEditing(false)
    }

    const handleCancelCancellation = () =>{
        addLoading("HANDLECANCELCANCELLATION")
        AccountsService.restartSubscription().then((response) =>{
            navigate(0)
        }).catch((error)=>{
            console.log("Failure: restartSubscription")
            //todo handle cancel cancellation in case of error
        })
    }

    return (
    <div>
      {isLoading() ? (
        <Loader />
      ) : (
          <div className={"account-wrapper"}>
              <div className={"account-header"}>
                  <span className={"account-title"}>
                      O λογαριασμός μου
                  </span>
                  <div className={"account-progress-section"}>
                      <div className={"account-progress-section-top"}>
                          <span>Συμπλήρωση προφίλ</span>
                          <span>{persentCompletion}%</span>
                      </div>
                      {persentCompletion === 100 ?
                          <span className={"account-progress-section-middle"}>Το προφίλ σου είναι συμπληρωμένο!</span>
                          :
                          <span className={"account-progress-section-middle"}>
                              Ολοκλήρωσε το προφίλ σου για να μπορείς να έχεις την ολοκληρωμένη εμπειρία του ANT1+
                          </span>
                      }
                      <div className={`account-progress-section-bot ${progressBarCompletion}`}></div>
                  </div>
              </div>
              <div className={"account-content"}>
                  <div className={"account-data"}>
                      <div className={"account-connect-data"}>
                          <span className={"account-connect-data-title"}>Στοιχεία σύνδεσης</span>
                          <div className={"account-connect-data-wrapper"}>
                              <div className={"account-textboxes-grouped"}>
                                  <TextBox fieldTitle={"EMAIL"}
                                           defaultValue={account.userData?.email} redirectEdit={()=> UserService.updateProfile(account.language)}
                                           hasIcon={account.userData?.emailVerified ? "images/emailVerified.svg" : "images/emailNeedsAction.png"}
                                           iconAlt={account.userData?.emailVerified ? "email is verified" : "email needs to be verified"}
                                           iconOnClick={!(account.userData?.emailVerified) ? ()=>{
                                               setVerificationPopup(true);
                                               setVerificationErrorPopup(false);
                                           } : undefined}/>
                                  <TextBox fieldTitle={"ΚΩΔΙΚΟΣ"} defaultValue={"************"} titleInfo={<div className={"account-code-tooltip-elements"}>
                                      <span>O κωδικός πρέπει να αποτελείται από τουλάχιστον:</span>
                                      <ul>
                                          <li>8 χαρακτήρες</li>
                                          <li>ένα κεφαλαίο γράμμα</li>
                                          <li>ένα πεζό γράμμα</li>
                                          <li>έναν αριθμό</li>
                                      </ul>
                                  </div>} type={"password"} redirectEdit={()=>UserService.updatePassword(account.language)} />
                              </div>
                              <div className={"account-ads-checkbox"}>
                                  <CheckBox disabled={marketingDisabled} action={onAcceptMarketingChange} checked={acceptMarketingCampaigns} text={"Αποδέχομαι να λαμβάνω ενημερώσεις και αποκλειστικές προσφορές από το antennaplus.gr."}/>
                              </div>
                          </div>
                      </div>
                      <div className={"account-personal-data"}>
                          <div className={"account-personal-data-header"}>
                              <span className={"account-personal-data-title"}>Προσωπικά στοιχεία</span>
                              {!isEditing ?
                                  <span className={"account-personal-data-header-edit"} onClick={()=>{setIsEditing(true)}}>Επεξεργασία</span>
                                  :
                                  <div className={"account-personal-data-header-isEditing account-desktop"}>
                                      <span className={"account-personal-data-header-cancel"} onClick={closeEdit}>Ακύρωση</span>
                                      <span className={"account-personal-data-header-edit"} onClick={handlePersonalSubmit}>Αποθήκευση</span>
                                  </div>
                              }
                          </div>
                          <div className={"account-personal-data-wrapper"} onDoubleClick={()=>{setIsEditing(true)}}>
                              <div className={"account-textboxes-grouped"}>
                                  <TextBox fieldTitle={"ΟΝΟΜΑ"} fieldName={"firstName"} defaultValue={formData.firstName} onChange={(e)=>{handleInputChange(e.target.name, e.target.value)}}  disabled={!isEditing}/>
                                  <TextBox fieldTitle={"ΕΠΙΘΕΤΟ"} fieldName={"lastName"} defaultValue={formData.lastName} onChange={(e)=>{handleInputChange(e.target.name, e.target.value)}} disabled={!isEditing}/>
                              </div>
                              <div className={"account-textboxes-grouped"}>
                                  <CustomDatePicker fieldTitle={"ΗΜΕΡΟΜΗΝΙΑ ΓΕΝΝΗΣΗΣ"} onChange={(date)=>{handleInputChange("dateOfBirth", date)}} defaultValue={formData.dateOfBirth} disabled={!isEditing}/>
                                  <CustomSelectBox fieldTitle={"ΦΥΛΟ"} fieldName={"gender"} defaultValue={formData.gender} customOnChange={(value) => {handleInputChange("gender", value)}} disabled={!isEditing} options={["Γυναίκα", "Άντρας", "Άλλο"]} placeholder={"Επιλογή"}/>
                              </div>
                              <div className={"account-textboxes-grouped"}>
                                  <TextBox fieldTitle={"ΤΗΛΕΦΩΝΟ"} fieldName={"phoneNumber"} type={"phoneNumber"} defaultValue={phoneNum} isError={isPhoneNumberError} onChange={(value) => {
                                      setIsPhoneNumberError(false);
                                      setPhoneNum(value)
                                  }} disabled={!isEditing} options={phoneNumberCodes}/>
                                  <span className={"account-textbox-placeholder"}>{isEditing ? "":""}</span>
                              </div>
                          </div>
                          {isEditing &&
                              <div className={"account-mobile"}>
                                  <div className={"account-personal-data-header-isEditing"}>
                                      <span className={"account-personal-data-header-cancel"} onClick={closeEdit}>Ακύρωση</span>
                                      <span className={"account-personal-data-header-edit"} onClick={handlePersonalSubmit}>Αποθήκευση</span>
                                  </div>
                              </div>
                          }

                      </div>
                  </div>
                  <div className={`subscriptionInformation-wrapper ${gradientGetter(account.userData?.subscription?.product.name)}`}>
                      <div className={`subscriptionInformation`}>
                          <div className={"subscriptionInformation-package"}>
                              <div className={"subscriptionInformation-package-content"}>
                                  <span className={"subscriptionInformation-package-title"}>Το πρόγραμμά μου</span>
                                  <div className={"subscriptionInformation-package-top"}>
                                      <span className={`subscriptionInformation-package-package ${gradientGetter(account.userData?.subscription?.product.name)}`}>{account.userData?.subscription?.product.name}</span>
                                      {((!hasUnsubscribed && (hasFreeProduct || canUpgrade)) && account.userData?.subscription?.state !== "canceled") &&
                                          <span className={"subscriptionInformation-package-upgrade"} onClick={onUpgrade}>Αλλαγή προγράμματος</span>
                                      }
                                  </div>
                                  {(hasUnsubscribed && !hasFreeProduct) || account.userData?.subscription?.state === "canceled"  ?
                                      <div className={"subscriptionInformation-hasUnsubscribed"}>
                                          <img src={"images/account/unsubscribeIcon.svg"} alt={"unsubscribe icon"} />
                                          {account.userData?.subscription?.state === "canceled" ?
                                              <span>Έχει πραγματοποιηθεί ακύρωση της συνδρομής σου. Θα μεταβείς στο πρόγραμμα ΑΝΤ1+ Free εντός των επόμενων ωρών.</span>:
                                              <span>Έχεις πραγματοποιήσει ακύρωση της συνδρομής σου και θα μεταβείς σε ΑΝΤ1+ Free στις {formatDate(account.userData?.subscription?.current_period_ends_at)}. Σε περίπτωση που άλλαξες γνώμη και θέλεις να παραμείνεις συνδρομητής πάτησε <span className={"clickable"} onClick={handleCancelCancellation}>εδώ</span></span>
                                          }
                                      </div>
                                  :
                                      !hasFreeProduct && (account.userData?.renewalPreview?.next_assessment_at) ?
                                      <>
                                          {account.userData?.subscription?.state !== "canceled" ?
                                              <div className={"subscriptionInformation-bot"}>
                                                  <div className={"subscriptionInformation-bot-texts"}>
                                                      <span className={"field-title"}>ΠΟΣΟ ΕΠΟΜΕΝΗΣ ΧΡΕΩΣΗΣ </span>
                                                      <span className={"field-data"}>{(account.userData?.subscription?.state ==="past_due" ? account.userData?.renewalPreview?.existing_balance_in_cents : account.userData?.renewalPreview?.total_amount_due_in_cents)/100}€</span>{/*todo*/}
                                                  </div>
                                                  <div className={"subscriptionInformation-bot-texts"}>
                                                      <span className={"field-title"}>ΗΜΕΡΟΜΗΝΙΑ ΕΠΟΜΕΝΗΣ ΧΡΕΩΣΗΣ </span>
                                                      <span className={"field-data"}>{formatDate(account.userData?.subscription?.state ==="past_due" ? account.userData?.subscription?.next_assessment_at : account.userData?.renewalPreview?.next_assessment_at)}</span>
                                                  </div>
                                              </div>
                                          :<></>}
                                          {account.userData?.subscription?.state ==="past_due" ?
                                              <div className={"subscriptionInformation-hasUnsubscribed past_due"}>
                                                  <img src={"images/account/unsubscribeIcon.svg"} alt={"unsubscribe icon"}/>
                                                  <span>H χρέωση της συνδρομής σου απέτυχε. Αυτό μπορεί να οφείλεται σε ανεπαρκές υπόλοιπο ή σε λήξη της αποθηκευμένης κάρτας. Η επόμενη προσπάθεια χρέωσης θα γίνει αυτόματα στις {formatDate(account.userData?.subscription?.next_assessment_at)}.</span>
                                              </div>:<></>
                                          }
                                      </>:<></>
                                  }
                              </div>
                              {(!hasUnsubscribed && !hasFreeProduct &&
                                  account.userData?.subscription?.state !== "canceled" &&
                                  account.userData?.subscription?.state !== "past_due") ?
                                  <span className={"subscriptionInformation-cancel"} onClick={onUnsubscribeHandle}>Θέλω να ακυρώσω τη συνδρομή μου</span>:<></>
                              }
                          </div>
                          {account.userData?.subscription?.state !== "canceled" ?
                              (!hasUnsubscribed && !hasFreeProduct && hasUsedCoupon && account.userData?.couponsInformation?.filter(item => item.discount_type !== "amount").slice(account.userData?.subscription?.coupon_uses_allowed - account.userData?.subscription?.coupon_use_count ? 0 : 1).length) ?
                                  <div className={`subscriptionInformation-voucher ${gradientGetter(account.userData?.subscription?.product.name)}`}>
                                      <div className={"subscriptionInformation-voucher-header"}>
                                          <span className={"subscriptionInformation-voucher-title"}>Ενεργό Κουπόνι</span>
                                          <span className={"subscriptionInformation-voucher-add desktop"} onClick={onCashCoupon}>Προσθήκη</span>
                                      </div>
                                      {account.userData?.couponsInformation?.filter(item => item.discount_type !== "amount").slice(account.userData?.subscription?.coupon_uses_allowed - account.userData?.subscription?.coupon_use_count ? 0 : 1).length ?
                                          <div className={"subscriptionInformation-voucher-wrapper"}>
                                              <div className={"subscriptionInformation-voucher-wrapper-child"}>
                                                  {account.userData?.couponsInformation.filter(item => item.discount_type !== "amount").slice(account.userData?.subscription?.coupon_uses_allowed - account.userData?.subscription?.coupon_use_count ? 0 : 1).map((item, index) =>{
                                                      return(<div key={index} className={`subscriptionInformation-voucher-item-wrapper ${account.userData?.couponsInformation?.filter(item => item.discount_type !== "amount").slice(account.userData?.subscription?.coupon_uses_allowed - account.userData?.subscription?.coupon_use_count ? 0 : 1).length === 1 ? "gapped":""}`}>
                                                              <span className={"subscriptionInformation-voucher-name"}>{item?.description}</span>
                                                          </div>)
                                                      })}
                                                  <div className={"subscriptionInformation-voucher-texts"}>
                                                      <span className={""}>ΥΠΟΛΟΙΠΟ ΔΩΡΕΑΝ ΧΡΗΣΗΣ:</span>
                                                      <span className={"field-data"}>
                                                          {(account.userData?.couponsInformation[0].discount_type === "amount") ?
                                                              account.userData?.couponsInformation?.filter(item => item.discount_type !== "amount").reduce((prev, item) => prev + item.duration_period_count+1, 0)
                                                              :
                                                              account.userData?.couponsInformation?.filter(item => item.discount_type !== "amount").slice(1).reduce((prev, item) => prev + item.duration_period_count+1, 0)+
                                                              account.userData?.subscription?.coupon_uses_allowed - account.userData?.subscription?.coupon_use_count
                                                          } μήνες</span>
                                                  </div>
                                              </div>
                                          </div>:<></>
                                      }
                                      <div className={"subscriptionInformation-voucher-header"}>
                                          <span className={"subscriptionInformation-voucher-add mobile"} onClick={onCashCoupon}>Προσθήκη</span>
                                      </div>
                                  </div>
                                  :
                                  <div className={`subscriptionInformation-voucher ${gradientGetter(account.userData?.subscription?.product.name)}`}>
                                      <div className={"subscriptionInformation-voucher-header"}>
                                          <span className={"subscriptionInformation-voucher-title"}>Έχεις κωδικό προσφοράς;</span>
                                      </div>
                                      <AccountEnterCoupon account={account} navigate={navigate} />
                                  </div>:<></>
                          }
                      </div>
                      {(account.userData?.vouchers?.filter(voucher => voucher?.provider === "VILLAGE").length &&
                              account.userData?.subscription?.product.name === "ANT1+ Sports Ultimate")  ?
                          <div className={"subscriptionInformation-village"}>
                              <div className={"subscriptionInformation-village-header"}>
                                  <span>Το εισιτήριό μου</span>
                                  <img src={"images/VILLAGE CINEMAS_LOGO Color White 2 - big.png"} alt={"village logo"}/>
                              </div>
                              <div className={"subscriptionInformation-village-content"}>
                                  <div className={"subscriptionInformation-village-voucher"} onClick={()=>{
                                      if(window.isSecureContext)
                                          navigator.clipboard.writeText(account.userData?.vouchers?.find(voucher => voucher?.provider === "VILLAGE").value);}}>
                                      <span>{account.userData?.vouchers?.find(voucher => voucher?.provider === "VILLAGE").value}</span>
                                      <img src={"images/account/copyIcon.svg"} alt={"click to copy on clipboard"}/>
                                  </div>
                                  <div className={"subscriptionInformation-village-field"}>
                                      <span className={"subscriptionInformation-village-field-title"}>Διαθέσιμο μέχρι </span>
                                      <span className={"subscriptionInformation-village-field-data"}>{periodEndDate}</span>
                                  </div>
                              </div>
                              <span className={"subscriptionInformation-village-description"}>Μπες στο villagecinemas.gr και βάλε τον κωδικό στο πεδίο «Προσθήκη voucher» κατά την αγορά του εισιτηρίου σου και δες δωρεάν όποια ταινία θέλεις!</span>
                          </div>
                          :<></>
                      }
                  </div>

                  {!!paymentProfileList.length ?
                      <div className={"card-section"}>
                      <span className={"card-section-header"}>Στοιχεία πληρωμής</span>
                      <div className={"card-section-data"}>
                          <div className={"card-section-data-data"}>
                              <div className={"card-title"}>
                                  <span>Οι κάρτες μου</span>
                                  <button disabled={paymentProfileList.length > 2} className={"card-title-button"} onClick={onChangePaymentInfo}>Προσθήκη</button>
                              </div>
                              <CardCarousel list={paymentProfileList}/>
                          </div>
                          {account.userData?.paymentsHistory?.filter(it => it.product_name !== "Free" && it.status === "paid").length ?
                              <div className={"card-section-history"}>
                                  <div className={"card-section-history-wrapper"}>
                                      <span className={"card-section-history-header"}>Ιστορικό χρεώσεων</span>
                                      <div className={"card-section-history-data"}>
                                          {account.userData?.paymentsHistory?.filter(it => it.product_name !== "Free" && it.status === "paid").slice(...(isHistoryExpanded ? [0] : [0, 3])).map((it, index) => {
                                              return (<div className={"card-section-history-item-wrapper"} key={index}>
                                                  <div className={`card-section-history-item ${gradientGetter(it.product_name)}`}>
                                                      <div className={"card-section-history-item-data"}>
                                                          <div className={"card-section-history-item-two-group"}>
                                                              <div className={"card-section-history-item-field"}>
                                                                  <span
                                                                      className={"card-section-history-item-field-top"}>ΠΡΟΓΡΑΜΜΑ</span>
                                                                  <span
                                                                      className={"card-section-history-item-field-bot"}>{it.product_name}</span>
                                                              </div>
                                                              <div className={"card-section-history-item-field"}>
                                                                  <span className={"card-section-history-item-field-top"}>ΠΟΣΟ ΧΡΕΩΣΗΣ</span>
                                                                  <span
                                                                      className={"card-section-history-item-field-bot"}>{it.paid_amount}€ </span>
                                                              </div>
                                                          </div>
                                                          <div className={"card-section-history-item-field"}>
                                                              <span className={"card-section-history-item-field-top"}>ΗΜΕΡΟΜΗΝΙΑ  ΧΡΕΩΣΗΣ</span>
                                                              <span
                                                                  className={"card-section-history-item-field-bot"}>{formatDate(it.paid_date)}</span>
                                                          </div>
                                                      </div>
                                                      {/*<div className={"card-section-history-item-receipt"} onClick={()=>{*/}
                                                      {/*    navigate("/invoice",  {*/}
                                                      {/*        state: {*/}
                                                      {/*            id: it,*/}
                                                      {/*        }});*/}
                                                      {/*}}>*/}
                                                      {/*    <img src={"images/account/downloadInvoice.svg"} alt={"view invoice"}/>*/}
                                                      {/*    <span>Λήψη Παραστατικού</span>*/}
                                                      {/*</div>*/}
                                                  </div>
                                              </div>)
                                          })}
                                      </div>
                                  </div>
                                  {account.userData?.paymentsHistory?.filter(it => it.product_name !== "Free" && it.status === "paid").length > 3 ?
                                      <>
                                          {isHistoryExpanded ?
                                              <span className={"card-section-history-expandMore"}
                                                    onClick={() => setIsHistoryExpanded(false)}>Λιγότερα</span> :

                                              <span className={"card-section-history-expandMore"}
                                                    onClick={() => setIsHistoryExpanded(true)}>Περισσότερα</span>

                                          }
                                      </>:<></>
                                  }
                              </div>: <></>
                          }
                      </div>
                  </div>:<></>
                  }
                  <div className={"disconnect-section"}>
                      <span onClick={logoutFromAllDevices}>Αποσύνδεση από όλες τις συσκευές</span>
                  </div>
              </div>
          </div>
        // <div className="box">
        //   {!matches ? (
        //     <DownloadApps
        //       text={`${t("accountDownloadAppsText")}`}
        //       grey={true}
        //       account={true}
        //     />
        //   ):(
        //       <div>
        //         <Button
        //         variant="text"
        //         className="blue"
        //         id="account-cta"
        //         onClick={onKeepWatching}>
        //           <ArrowBackIcon id="cta-icon" />
        //           {t("accountCTAText")}
        //         </Button>
        //       </div>
        //   )}
        //
        //   <div className="account-wrapper">
        //     <h3>{t("accountTitle")}</h3>
        //     <div>
        //       <Grid
        //         container
        //         spacing={matches ? 4 : 1}
        //         className="account-grid-container user-info"
        //       >
        //         <Grid item xs={6} md={2}>
        //           <span className="title">{t("accountUserInfo")}</span>
        //         </Grid>
        //         <Grid item xs={12} md="auto" className="account-grid-wrapper">
        //           <div
        //             className={`info-wrapper ${
        //               account.userData.email.length > 26 ? "long" : ""
        //             }`}
        //           >
        //             <div className={"info-wrapper-email"}>
        //                 <span className="info">Email:</span>
        //                 <div className={"info-email-data-wrapper"}>
        //                     <div className={`info-wrapper-email-inside ${account.userData.emailVerified ? "":"img-email-needs-action"} `}
        //                          onClick={()=> {
        //                              if(!account.userData.emailVerified) {
        //                                 setVerificationPopup(true);
        //                                 setVerificationErrorPopup(false);
        //                              }
        //                          }}>
        //                           {account.userData.emailVerified ?(
        //                               <img src={"images/emailVerified.png"} alt={"email is verified"}/>
        //                           ):(
        //                               <img src={"images/emailNeedsAction.png"} alt={"email needs to be verified"} />
        //                           )}
        //                         <span>{account.userData.email}</span>
        //                     </div>
        //                     <span id="email-change" className={"blue"} onClick={onResetEmail}>
        //                         {t("accountChangeInfoButtonText")}
        //                     </span>
        //                 </div>
        //             </div>
        //           </div>
        //         </Grid>
        //         <Grid item xs={12} md="auto" className={"gridStyle"}>
        //           <span className="info-wrapper">
        //             <span>
        //               <span className="info">{t("accountPassword")}</span>
        //               ************
        //             </span>
        //             <span>
        //               <Button
        //                 variant="text"
        //                 className="blue"
        //                 onClick={onResetPassword}
        //               >
        //                 {t("accountChangeInfoButtonText")}
        //               </Button>
        //             </span>
        //           </span>
        //         </Grid>
        //       </Grid>
        //       <Grid
        //         container
        //         spacing={matches ? 4 : 1}
        //         className="account-grid-container package"
        //         alignItems={!matches ? "center" : "flex-start"}
        //       >
        //         <Grid item xs="auto" md={2}>
        //           <span className="title">{t("accountPackage")}</span>
        //         </Grid>
        //         <Grid item xs={6} md="auto">
        //           {account.userData.subscription && (
        //             <div id="active-program">
        //               {account.userData.subscription.product.name}
        //             </div>
        //           )}
        //         </Grid>
        //         {hasUsedCoupon && (
        //           <Grid item xs={12} md="auto">
        //             <div id="coupon-in-use">{customCouponMessage}</div>
        //           </Grid>
        //         )}
        //       </Grid>
        //       {(hasFreeProduct || canUpgrade) && (
        //         <Grid
        //           container
        //           spacing={matches ? 4 : 1}
        //           className="account-grid-container coupon"
        //         >
        //           <Grid item xs={12} md={2}></Grid>
        //           <Grid item xs={12} md="auto">
        //             <p id="upgrade-text">{t("accountUpgradeText")}</p>
        //           </Grid>
        //           <Grid container={!matches} item xs={12} md="auto">
        //             <div id="upgradeButton-wrapper">
        //               <Button
        //                 variant="contained"
        //                 color="primary"
        //                 onClick={onUpgrade}
        //                 id="upgradeButton"
        //               >
        //                 {t("accountUpgradeButtonText")}
        //               </Button>
        //             </div>
        //           </Grid>
        //         </Grid>
        //       )}
        //       <Grid
        //         container
        //         spacing={matches ? 4 : 1}
        //         className="account-grid-container coupon"
        //       >
        //         <Grid item xs={12} md={2}></Grid>
        //         <Grid item xs={12} md="auto">
        //           <span>
        //             <Button
        //               variant="text"
        //               className="blue"
        //               id="useCouponButton"
        //               onClick={onCashCoupon}
        //             >
        //               {t("accountCashCouponButton")}
        //             </Button>
        //           </span>
        //         </Grid>
        //       </Grid>
        //       {showVoucher && voucher && (
        //         <Grid
        //           container
        //           spacing={matches ? 4 : 1}
        //           className="account-grid-container village-voucher"
        //         >
        //           <Grid item xs={12} md={2}>
        //             <span className="title">
        //               Το εισιτήριό σου {matches && <br />} για τα{" "}
        //               <img
        //                 src="images/VILLAGE CINEMAS_LOGO Color White 2 - big.png"
        //                 alt="village logo"
        //               />
        //               :
        //             </span>
        //           </Grid>
        //           <Grid item xs={12} md={10}>
        //             <div id="village-info">
        //               <p>
        //                 Μπες στο villagecinemas.gr, βάλε τον κωδικό στο πεδίο
        //                 «Προσθήκη voucher» κατά την αγορά του εισιτηρίου σου και
        //                 δες δωρεάν όποια ταινία θέλεις! Δες τις οδηγίες{" "}
        //                 <a
        //                   href="http://help.antennaplus.gr/kb/πώς-παίρνω-το-εισιτήριό-μου-για-τα-village-cinemas/"
        //                   target="_blank"
        //                   rel="noreferrer"
        //                 >
        //                   εδώ
        //                 </a>
        //               </p>
        //
        //               <div id="village-copy">
        //                 <input
        //                   type="text"
        //                   id="voucher-value"
        //                   name="voucher-value"
        //                   className={`${
        //                     ![-1, 0, 1].includes(voucher.status) ? "used" : ""
        //                   }`}
        //                   value={voucher.value
        //                     .toString()
        //                     .replace(/\d{4}(?=.)/g, "$& ")}
        //                   readOnly
        //                 ></input>
        //
        //                 {[-1, 0, 1].includes(voucher.status) && (
        //                   <Button
        //                     variant="text"
        //                     className="blue"
        //                     onClick={() => {
        //                       navigator.clipboard.writeText(voucher.value);
        //                     }}
        //                   >
        //                     Αντιγραφή
        //                   </Button>
        //                 )}
        //               </div>
        //
        //               {voucher.status === -1 ? (
        //                 <span>
        //                   Οι πληροφορίες του κωδικού δεν είναι διαθέσιμες αυτή
        //                   τη στιγμή, προσπάθησε ξανά αργότερα.
        //                 </span>
        //               ) : [0, 1].includes(voucher.status) ? (
        //                 <span>
        //                   Μπορείς να χρησιμοποιήσεις τον κωδικό μέχρι τις{" "}
        //                   {periodEndDate}
        //                 </span>
        //               ) : (
        //                 <span>
        //                   Ο κωδικός έχει εξαργυρωθεί.
        //                   {!hasUnsubscribed && (
        //                     <> Επόμενος διαθέσιμος στις {periodEndDate}</>
        //                   )}
        //                 </span>
        //               )}
        //             </div>
        //           </Grid>
        //         </Grid>
        //       )}
        //       {paymentProfile && (
        //         <Grid
        //           container
        //           spacing={matches ? 4 : 1}
        //           className="account-grid-container payment"
        //         >
        //           <Grid item xs={12} md={2}>
        //             <span className="title">{t("accountPaymentInfo")}</span>
        //           </Grid>
        //           <Grid item xs={12} md="auto">
        //             {/* {!hasUnsubscribed && ( */}
        //             <div className="payment-info">
        //               <div className="card-details">
        //                 <span>{maskedCardNumber}</span>
        //                 <span>
        //                   <Button
        //                     variant="text"
        //                     className="blue"
        //                     onClick={onChangePaymentInfo}
        //                   >
        //                     {t("accountChangeInfoButtonText")}
        //                   </Button>
        //                 </span>
        //                 {/* <IconButton
        //                     aria-label="delete card"
        //                     component="label"
        //                     onClick={onDeleteCardDetailsRequest}
        //                     id="delete-card-button-desktop"
        //                   >
        //                     <img
        //                       src="images/delete-card-icon.png"
        //                       alt="delete card icon"
        //                     />
        //                   </IconButton> */}
        //               </div>
        //               {!hasUnsubscribed && (
        //                 <div className="next-charge-date">
        //                   <span className="date">
        //                     {t("accountNextPaymentDate")}
        //                     {periodEndDate}
        //                   </span>
        //                 </div>
        //               )}
        //               {/* <div id="delete-card-button-mobile-wrapper">
        //                   <Button
        //                     variant="text"
        //                     onClick={onDeleteCardDetailsRequest}
        //                     id="delete-card-button-mobile"
        //                   >
        //                     Διαγραφή κάρτας
        //                   </Button>
        //                 </div> */}
        //             </div>
        //             {/* )} */}
        //
        //             {/* {hasUnsubscribed && (
        //               <div className="no-payment-info">
        //                 <div className="no-card-message">
        //                   Δεν υπάρχει διαθέσιμη κάρτα
        //                 </div>
        //                 <div className="next-charge-date">
        //                   <span className="date">
        //                     {t("accountSubscriptionActiveUntil")}
        //                     {periodEndDate}
        //                   </span>
        //                 </div>
        //               </div>
        //             )} */}
        //           </Grid>
        //         </Grid>
        //       )}
        //       <Grid
        //         container
        //         spacing={matches ? 4 : 1}
        //         className="account-grid-container"
        //       >
        //         <Grid item xs={12} md={2}>
        //           <span className="title">{t("accountSettings")}</span>
        //         </Grid>
        //         <Grid item xs={12} md="auto">
        //           <div id="account-accordeon-wrapper">
        //             {questions.map((item, index) => (
        //               <Accordion
        //                 className={classes.accordion}
        //                 key={index}
        //                 expanded={expanded === `panel${index}`}
        //                 onChange={handleAccordionChange(`panel${index}`)}
        //                 id={`${
        //                   item.checkHidden
        //                     ? hasUnsubscribed
        //                       ? "hidden-unsubscribe-button"
        //                       : ""
        //                     : ""
        //                 }`}
        //               >
        //                 <AccordionSummary
        //                   expandIcon={<AddIcon className={classes.icon} />}
        //                   aria-controls={`panel${index}a-content`}
        //                   id={`panel${index}a-header`}
        //                 >
        //                   <Typography>{item.title}</Typography>
        //                 </AccordionSummary>
        //                 <AccordionDetails>{item.description}</AccordionDetails>
        //               </Accordion>
        //             ))}
        //           </div>
        //         </Grid>
        //       </Grid>
        //     </div>
        //   </div>
        // </div>
      )}
      <PopUp
          openPopUp={verificationPopup}
          setOpenPopUp={setVerificationPopup}
          maxWidth="md"
          showCloseButton={true}
          outlined={false}
          error={false}
          closeWhenClickedOutside={true}
      >
        <GenericError
            setClose={setVerificationPopup}
            success={true}
            mainText={"Μη επιβεβαιωμένο email"}
            buttonText={"Αποστολή email"}
            showIcon={false}
            executeFunction={sendVerificationEmail}>
            <div className={"account-email-popup-secondary-wrapper"}>
                <span className={"account-email-popup-secondary-first"} >Κάνε κλικ στον παρακάτω σύνδεσμο για να λάβεις ένα μήνυμα επιβεβαίωσης στο email που μας έχεις δηλώσει.</span>
                <span className={"account-email-popup-secondary-sub"} >Παρακαλούμε ελέγξτε και τον φάκελο με τα ανεπιθύμητα.</span>
            </div>
        </GenericError>
      </PopUp>
      <PopUp
          openPopUp={verificationErrorPopup}
          setOpenPopUp={setVerificationErrorPopup}
          maxWidth="md"
          showCloseButton={true}
          outlined={false}
          error={false}
          closeWhenClickedOutside={true}
      >
        <GenericError
            setClose={setVerificationErrorPopup}
            success={false}
            mainText={"Problem with sending email verification"}
            secondaryText={"There was a problem with sending your email verification. Please try again later."}
            hideButtons={true}
            showIcon={true}
        />
      </PopUp>
      <PopUp
          openPopUp={verificationSuccessPopup}
          setOpenPopUp={setVerificationSuccessPopup}
          maxWidth="md"
          showCloseButton={true}
          outlined={false}
          error={false}
          closeWhenClickedOutside={true}
      >
        <GenericError
            setClose={setVerificationSuccessPopup}
            success={true}
            mainText={"Εστάλη email επιβεβαίωσης"}
            hideButtons={true}
            showIcon={true}>
            <div className={"account-email-popup-secondary-wrapper"}>
                <span className={"account-email-popup-secondary-sub"} >Παρακαλούμε ελέγξτε και τον φάκελο με τα ανεπιθύμητα.</span>
            </div>
        </GenericError>
      </PopUp>
        <PopUp
            openPopUp={phoneNumberVerify}
            setOpenPopUp={setPhoneNumberVerify}
            maxWidth="md"
            showCloseButton={true}
            outlined={false}
            error={false}
            closeWhenClickedOutside={true}
        >
            <PinVerification
                showBackButton={false}
                isModal={true}
                mobile={phoneNum}
                successAction={(token)=>{
                    addLoading("ACCOUNTPINVERIFICATIONSUCCESS")
                    AccountsService.patchUser({phoneNumber: phoneNum, flowToken: token  })
                        .then((response) => {
                            navigate(0);
                        })
                        .catch((err) => {

                        })
                        .finally(()=>{
                            removeLoading("ACCOUNTPINVERIFICATIONSUCCESS")
                        })
                }}
            />
        </PopUp>
    </div>
  );
}


function AccountEnterCoupon({account, navigate}){
    const [coupon, setCoupon] = useState("");
    const [error, setError] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);

    const handleChange = (e) => {
        e.preventDefault();
        setInputError(false);
        //setDisabledButton(e.target.value.length !== 16);
        setDisabledButton(e.target.value.replace(/\s/g, "").length === 0);
        setCoupon(e.target.value);
        setError(false);
    };

    const onValidateCoupon = (e) => {
        e.preventDefault();
        setDisabledButton(true);
        CouponsService.checkCouponValidity(coupon)
            .then((response) => {
                setError(false);
                sessionStorage.setItem("discount-coupon", coupon);
                if (UserService.isLoggedIn()) {
                    navigate("/PickProgram");
                } else {
                    // console.debug("Retrying authorization")
                    try{
                        UserService.doLogin(process.env.REACT_APP_ENTER_COUPON_REDIRECT_URI,account.language);
                    }catch (e){
                        // console.debug("Error slow connection")
                    }
                }
            })
            .catch((error) => {
                setError(true);
            })
            .finally(() => {
                setDisabledButton(false);
            });
    };
    return (<div id="account-enter-coupon">
            <form autoComplete="off" onSubmit={onValidateCoupon} className={"account-coupon-wrapper"}>
                <div className={"account-coupon-inner-wrapper"}>
                    <div className={`account-coupon-textbox-wrapper ${error || inputError ? "account-coupon-error" : ""}`}>
                        <div className={"account-coupon-input"}>
                            <input type={"text"} placeholder={"Πληκτρολόγησε τον κωδικό εδώ"} onChange={handleChange} value={coupon}/>
                        </div>
                        <button type={"submit"}
                                disabled={disabledButton}
                        >
                            <div className={"account-coupon-textbox-button"}></div>
                        </button>
                    </div>
                    {(error || inputError) &&
                        <span>Ο κωδικός δεν είναι έγκυρος</span>
                    }
                </div>
            </form>
    </div>)
}

export default Account;